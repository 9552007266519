import PageNavbar from "../Components/PageNavbar";
import { HomePageImage, HomePageTopText } from "../Components/HomePageComponents";
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '50px',
    fontFamily: 'Arial, sans-serif'
  },
  textContainer: {
    maxWidth: '50%',
  },
  heading: {
    fontSize: '3em',
    fontWeight: 'bold',
  },
  subHeading: {
    fontSize: '2em',
    color: '#005C7B',
  },
  description: {
    fontSize: '1.2em',
    color: '#555',
    margin: '20px 0',
  },
  emailInput: {
    padding: '10px',
    fontSize: '1em',
    borderRadius: '25px',
    border: '1px solid #ddd',
    marginRight: '10px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '1em',
    borderRadius: '25px',
    backgroundColor: '#005C7B',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
  },
  imageContainer: {
    maxWidth: '40%',
  },
  image: {
    width: '100%',
    height: 'auto',
  }
};
function Home() {
  return (
    <>
    <PageNavbar/>
    <HomePageTopText/> 
    </>
  );
  
  }
  
  export default Home;
  