import logo from './DebatehubLogo.png'
import { Navbar, Nav, NavDropdown, Form, FormControl, Button, Container } from 'react-bootstrap';
import { useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './PageNavbar.css'; 
import { auth } from '../Pages/firebase'; // Adjust the path as necessary
import { onAuthStateChanged, signOut } from 'firebase/auth';
const secondaryBlue = "#50BFE6";
function PageNavbar() {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/Login');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

    return (
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary navbar-custom" >
      <Container>
        <Navbar.Brand style={{ color: '#50BFE6' }} onClick={() => navigate("/")}>
          Debate Hub
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
          </Nav>
          <Nav>
            <Nav.Link style={{ color: '#50BFE6' }} onClick={() => navigate("/Chatbot")}>Chatbot</Nav.Link>
            <Nav.Link style={{ color: '#50BFE6' }} onClick={() => navigate("/Dashboard")}>Dashboard</Nav.Link>
            <Nav.Link style={{ color: '#50BFE6' }} onClick={() => navigate("/Vision")}>Vision</Nav.Link>

            {isAuthenticated ? (
              <Nav.Link style={{ color: '#50BFE6' }} onClick={handleLogout}>Logout</Nav.Link>
            ) : (
              <Nav.Link style={{ color: '#50BFE6' }} onClick={() => navigate("/Login")}>Login</Nav.Link>
            )}
            <Nav.Link style={{ color: '#50BFE6' }} onClick={() => navigate("/Signup")}>Signup</Nav.Link>

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
  }
  
  export default PageNavbar;
  