import {FriendlyText,ChatbotInputField,GeneratedResponse,ChatbotCards} from "../Components/ChabotComponents";
import PageNavbar from "../Components/PageNavbar";

function Chatbot() {
    return (
      <>
      <PageNavbar/>
      <FriendlyText/>
      <ChatbotCards/>
      <ChatbotInputField/>
     
      </>
  );
  }

  export default Chatbot;