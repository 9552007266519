import React from "react";
import PageNavbar from "./PageNavbar";
import VisionPage from "./VisionComponent";
import { defaultUrlTransform } from "react-markdown";

function Vision(){
    return(
        <div>
        <PageNavbar/>
        <VisionPage/>
        </div>
    )
}
export default Vision;