import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useNavigate,useLocation } from 'react-router-dom';
import './MarkdownResponsePage.css';

function MarkdownResponsePage(){
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const response = queryParams.get('response') || 'No response found';
    return (
        <div className="pdf-page">
            <button className="back-button" onClick={() => navigate(-1)}>Back</button>
            <div className="markdown-content">
                <ReactMarkdown>{response}</ReactMarkdown>
            </div>
        </div>
    );

  };

export default MarkdownResponsePage;