// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getAuth} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {

  apiKey: "AIzaSyCX7azcxz5bKHMYA7ttpUT7GPArHyfwqok",

  authDomain: "debatehub-c26fe.firebaseapp.com",

  projectId: "debatehub-c26fe",

  storageBucket: "debatehub-c26fe.appspot.com",

  messagingSenderId: "287279106070",

  appId: "1:287279106070:web:caae1cf051a711bc224d37",

  measurementId: "G-GPX02N67BS"

};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth=getAuth();
export const db=getFirestore(app);
//const analytics = getAnalytics(app);
export default {app,db};