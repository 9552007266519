import { useState,useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ChatbotCards.css'
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { toast } from "react-toastify";
import Placeholder from 'react-bootstrap/Placeholder';
import { auth, db } from '../Pages/firebase'; // Adjust the path as necessary
import { doc, setDoc, collection, addDoc, onSnapshot } from 'firebase/firestore';
import { useMediaQuery } from 'react-responsive';
const primaryBlue = "#4A90E2";
const lightBlue = "#ADD8E6";
const softBlue = "#B0E0E6";
const mediumBlue = "#87CEEB";
const secondaryBlue = "#50BFE6";

export function FriendlyText() {
    return (
        <div style={{ textAlign: 'center', backgroundColor: 'white', padding: '20px', fontFamily: "'Roboto', sans-serif" }}>
            <h1 style={{ color: mediumBlue, fontWeight: '700', textShadow: '1px 1px 2px rgba(0, 0, 0, 0.1)', margin: '20px 0' }}>
                How can I help you today?
            </h1>
            <h5 style={{ color: secondaryBlue, fontWeight: '400', margin: '10px 0', fontSize: '1.2rem', lineHeight: '1.6',  }}>
                Use this tool in preparation for your debates. It will give quality material, feedback, and it will help cite your sources.
            </h5>
            <h5 style={{ color: secondaryBlue, fontWeight: '400', margin: '10px 0', fontSize: '1.2rem', lineHeight: '1.6', }}>
                Afterwards feel free to export the generated response to a PDF for future study or to share with your friends and colleagues.
            </h5>
        </div>
    );
}
export function ChatbotInputField() {
  const [prompt, setPrompt] = useState('');
  const [response, setResponse] = useState('');
  const user = auth.currentUser;
  const handleSubmit = async () => {
    const user = auth.currentUser;
    if (!user) {
      console.error('User not authenticated');
      return;
    }
  
    const userId = user.uid;
    const userGenerateCollectionPath = `Users/${userId}/generate`;
  
    try {
      const generateDocRef = await addDoc(collection(db, userGenerateCollectionPath), {
        prompt: prompt,
        response: '', // Initialize response as empty
        timestamp: new Date(),
      });
  
      // Listen for changes to the document
      const unsub = onSnapshot(generateDocRef, (doc) => {
        if (doc.exists()) {
          const data = doc.data();
          if (data.response) {
            setResponse(data.response);
            toast.success("Response received!", {
              position: "top-center",
            });
          }
        }
      });
  
      toast.success("Prompt submitted successfully", {
        position: "top-center",
      });
    } catch (error) {
      console.error('Error generating text:', error);
      toast.error('Error generating text. Please try again.', {
        position: "top-center",
      });
    }
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', marginTop: '25px' }}>
      <InputGroup size="lg" style={{ width: '100%', maxWidth: '800px', borderRadius: '15px', overflow: 'hidden', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
      <Form.Control
        aria-label="Large"
        aria-describedby="inputGroup-sizing-sm"
        style={{ border: 'blue', padding: '15px ', fontSize: '1rem', borderRadius: '' }}
        placeholder="Type a message"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />
      <Button
        variant="outline-secondary"
        id="button-addon1"
        style={{ backgroundColor: 'white', border: 'none', padding: '10px', borderRadius: '0 30px 30px 0',color:secondaryBlue,fontWeight:'bold' }}
        onClick={handleSubmit}
      >
        <i className="fa fa-paper-plane" style={{ fontSize: '1.5rem', color: 'grey' }}></i>
        Generate
      </Button>
    </InputGroup>
      {response && <GeneratedResponse response={response} />}
    </div>
  );
  
}
export function GeneratedResponse({ response }) {
  return (
    <Card style={{ width: '600px', margin: '20px 0', padding: '20px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
      <Card.Body>
        <Card.Text>{response}</Card.Text>
      </Card.Body>
    </Card>
  );
}

export function ChatbotCards() {
  const isMobile = useMediaQuery({ maxWidth: 576 });

  const debatePrompts = [
    "Should the voting age be lowered to 16?",
    "Is a universal basic income a viable solution for economic inequality?",
    "Should schools implement mandatory uniform policies?",
    "Is social media beneficial or harmful to society?",
    "Should college education be free for all students?"
  ];
  if (isMobile) {
    return null;
  }
  return (
    <div className="d-flex flex-wrap justify-content-center ">
      {debatePrompts.map((prompt, index) => (
        <Card 
          key={index} 
          style={{ 
            width: '18rem', 
            margin: '10px', 
            height: '250px', 
            border: '1px solid #dee2e6', 
            borderRadius: '10px', 
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' ,
          }}
        >
          <Card.Body className="d-flex align-items-center justify-content-center">
            <Card.Text style={{ fontSize: '1rem', textAlign: 'center', margin: '0', padding: '0' }}>
              {prompt}
            </Card.Text>
          </Card.Body>
        </Card>
      ))}
    </div>
  );
}