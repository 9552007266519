import React from 'react';
import PageNavbar from './PageNavbar'; // Assuming you have this component
import {RecentChats,UpcomingTournaments} from './DashboardComponents';
import './Dashboard.css';

const Dashboard = () => {
    return (
        <>
            <PageNavbar />
            <div className="dashboard-container">
                <RecentChats />
                <UpcomingTournaments />
            </div>
        </>
    );
}

export default Dashboard;
