import React from 'react';
import './Dashboard.css';
import { useEffect,useState } from 'react';
//import { auth,db, } from '../Pages/firebase';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import {collection,getDocs,getFirestore,query,where,onSnapshot} from 'firebase/firestore';
import { toast } from 'react-toastify';
import { useNavigate,Link } from 'react-router-dom';
const mediumBlue = "#87CEEB";
const secondaryBlue = "#50BFE6";
const db = getFirestore();
const auth = getAuth();
export const Card = ({ title, subtitle,linkTo }) => (
  <Link to={linkTo} style={{ textDecoration: 'none', color: 'inherit' }}>
    <div className="card" style={{ backgroundColor: mediumBlue }}>
        <div className="card-header">{title}</div>
        {subtitle && <div className="card-subheader" style={{ color: "black",fontSize:12 }}>{subtitle}</div>}
    </div>
    </Link>
);


export const RecentChats = () => {
  const [prompts, setPrompts] = useState([]);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
 
  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    return () => unsubscribeAuth();
  }, []);

  useEffect(() => {
    if (user) {
      const userId = user.uid;
      const userGenerateCollectionPath = `Users/${userId}/generate`;
      const q = query(collection(db, userGenerateCollectionPath), where('prompt', '!=', ''));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const promptsArray = [];
        querySnapshot.forEach((doc) => {
          promptsArray.push({ id: doc.id, ...doc.data() });
        });
        setPrompts(promptsArray);
      }, (error) => {
        console.error("Error fetching user prompts: ", error);
        toast.error('Error fetching user prompts. Please try again.', {
          position: "top-center",
        });
      });

      return () => unsubscribe(); // Cleanup on unmount
    }
  }, [user]);

  return (
    <div>
      <h1 className="section-title">Recent Chats</h1>
      <div className="card-container">
        {prompts.length > 0 ? (
          prompts.map((chat) => (
            <Card
              key={chat.id}
              title={chat.prompt}
              subtitle={chat.output}
              linkTo={`/Markdown?response=${encodeURIComponent(chat.output)}`}

            />
          ))
        ) : (
          <p>No recent chats available.</p>
        )}
      </div>
    </div>
  );
};

export const UpcomingTournaments = () => {
  const [tournaments, setTournaments] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const tournamentsCollection = collection(db, 'tournaments');
        const tournamentSnapshot = await getDocs(tournamentsCollection);
        const tournamentList = tournamentSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTournaments(tournamentList);
      } catch (error) {
        console.error('Error fetching tournaments:', error);
      }
    };

    fetchTournaments();
  }, []);

  return (
    <div>
      <h1 className="section-title">Upcoming Tournaments</h1>
      <div className="card-container">
        {tournaments.length > 0 ? (
          tournaments.slice(0, 5).map((tournament) => (
            <Card key={tournament.id} title={tournament.circuit_name} subtitle={tournament.abbr} linkTo={'/Tournaments'} />
          ))
        ) : (
          <p>No upcoming tournaments available.</p>
        )}
      </div>
      <button className="view-all-button" onClick={() => navigate('/Tournaments')}>
        View All Tournaments
      </button>
    </div>
  );
};