import React, { useEffect, useState } from 'react';
import { auth,db, } from '../Pages/firebase';
import {collection,getDocs} from 'firebase/firestore';
import axios from 'axios';
import './CircuitsTable.css';  
function TournamentComponent() {
  const [tournaments, setTournaments] = useState([]);

  useEffect(() => {
    const fetchTournaments = async () => {
      try {
        const tournamentsCollection = collection(db, 'tournaments');
        const tournamentSnapshot = await getDocs(tournamentsCollection);
        const tournamentList = tournamentSnapshot.docs.map(doc => doc.data());
        setTournaments(tournamentList);
      } catch (error) {
        console.error('Error fetching tournaments:', error);
      }
    };

    fetchTournaments();
  }, []);

  return (
    <div className="table-container">
      <table className="styled-table">
        <thead>
          <tr>
            <th>Abbreviation</th>
            <th>Circuit Name</th>
            <th>Tournaments</th>
          </tr>
        </thead>
        <tbody>
          {tournaments.map((tournament, index) => (
            <tr key={index} className={index % 2 === 0 ? 'even-row' : 'odd-row'}>
              <td>{tournament.abbr}</td>
              <td>{tournament.circuit_name}</td>
              <td>{tournament.tournaments}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default TournamentComponent