import React from 'react';
import './VisionPage.css';
import PageNavbar from './PageNavbar';

export function VisionPage() {
  return (
    <>
      <section className="vision">
        <div className="overlayv"></div>
        <div className="textv">
          <h2>Vision</h2>
          <p>Our vision is to transcend the ordinary and embrace a future where possibilities are limitless. We aspire to be a beacon of innovation, setting new standards in every industry we touch. Our commitment is to cultivate an environment where creativity thrives and groundbreaking ideas come to life, pushing the boundaries of what is possible.</p>

<p>We envision a world where our solutions seamlessly integrate into everyday lives, enhancing experiences and fostering connections. Our focus is on creating value that extends beyond the immediate, touching the lives of individuals and communities alike. We are dedicated to driving positive change, one that resonates on a global scale and leaves a lasting impact.</p>

<p>At the core of our vision is a dedication to excellence. We strive to exceed expectations and deliver unparalleled quality in all our endeavors. Our pursuit of perfection is unwavering, and we continually seek ways to improve, innovate, and adapt in a rapidly changing world. We believe that by maintaining the highest standards, we can achieve greatness and inspire others to do the same.</p>

<p>Integrity is the foundation of our vision. We operate with transparency, honesty, and respect in all our interactions. We believe that trust is earned through consistent and ethical behavior, and we are committed to building and maintaining strong relationships with our stakeholders. Our actions are guided by a moral compass that prioritizes doing what is right, even when it is not the easiest path.</p>

<p>Collaboration is a cornerstone of our vision. We recognize that the best solutions come from diverse perspectives and collective effort. By fostering a culture of teamwork and inclusivity, we create an environment where everyone’s voice is heard and valued. We believe in the power of partnerships, both internal and external, to drive innovation and achieve common goals.</p>


        </div>
      </section>
    </>
  );
}

export default VisionPage;
