import PageNavbar from "../Components/PageNavbar";

function Mentors(){
    return(
        <>
        <PageNavbar/>
        <h1>Mentors</h1>
        </>
    )
}
export default Mentors;