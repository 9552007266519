import logo from './logo.svg';
import './App.css';
import Home from './Pages/Home';
import {BrowserRouter,Routes,Route} from 'react-router-dom'
import Chatbot from './Pages/Chatbot';
import Tournaments from './Pages/Tournaments';
import Dashboard from './Components/Dashboard';
import Mentors from './Pages/Mentors';
import Signup from './Pages/Signup';
import Login from './Pages/Login';
import MarkdownResponsePage from './Components/Markdown';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Vision from './Components/VisionPage';
function App() {
  return (
    <>
      <BrowserRouter>
        <div>
          <ToastContainer/>
        <Routes>
          <Route 
          exact
          path="/"
          element={<Home/>}/>
          
          <Route 
          exact
          path="/Chatbot"
          element={<Chatbot/>}/>
          
          <Route 
          exact
          path="/Tournaments"
          element={<Tournaments/>}/>
          
          <Route 
          exact
          path="/Dashboard"
          element={<Dashboard/>}/>
          <Route 
          exact
          path="/Mentors"
          element={<Mentors/>}/>

          <Route 
          exact
          path="/Signup"
          element={<Signup/>}/>
          <Route 
          exact
          path="/Login"
          element={<Login/>}/>
          <Route 
          exact
          path="/Vision"
          element={<Vision/>}/>
          <Route  
          exact
          path="/Markdown" 
          element={<MarkdownResponsePage />} />
      </Routes>
      </div>
      </BrowserRouter>
    
    
    </>
  );
}

export default App;
