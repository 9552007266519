import React from 'react';
import logo from './NotebookImg.jpg';
import owner from './Abhirup.jpg';
import backgroundImg from './DebatehubLogo.png'; // Import your background image
import { useNavigate } from 'react-router-dom';
import './Homepage.css'
const mediumBlue = "#87CEEB";
const secondaryBlue = "#50BFE6";
const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    padding: '50px',
    fontFamily: 'Arial, sans-serif',
    minHeight: '80vh',
    backgroundImage: `url(${backgroundImg})`,
    backgroundSize: 'cover',
    color: 'white',
  },
  textContainer: {
    maxWidth: '50%',
    paddingTop: '50px',
  },
  heading: {
    fontSize: '3em',
    fontWeight: 'bold',
    color: secondaryBlue,
    marginBottom: '10px',
  },
  subHeading: {
    fontSize: '2em',
    fontWeight: 'bold',
    color: "black",
  },
  description: {
    fontSize: '1.2em',
    color: secondaryBlue,
    margin: '20px 0',
  },
  emailInput: {
    padding: '10px',
    fontSize: '1em',
    borderRadius: '25px',
    border: '1px solid #ddd',
    marginRight: '10px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '1em',
    borderRadius: '25px',
    backgroundColor: '#005C7B',
    color: '#fff',
    border: 'none',
    cursor: 'pointer',
  },
  imageContainer: {
    maxWidth: '40%',
  },
  image: {
    width: '75%',
    height: 'auto',
  },
  additionalContent: {
    textAlign: 'center',
    padding: '50px',
    fontFamily: 'Arial, sans-serif',
  },
  headshot: {
    borderRadius: '50%',
    width: '150px',
    height: '150px',
    marginBottom: '20px',
  },
  quote: {
    fontSize: '1.5em',
    fontStyle: 'italic',
  },
  ownerName: {
    marginTop: '10px',
    fontSize: '1.2em',
    fontWeight: 'bold',
  },
};
/*
export function HomePageTopText() {
  return (
    <div>
      <div style={styles.container}>
        <div style={styles.textContainer}>
          <h1 style={styles.heading}>Best way for debate preparation.</h1>
          <h1 style={styles.subHeading}>The technology to give you an edge.</h1>
        </div>
      </div>
      <div style={styles.additionalContent}>
        <img src={owner} alt="Owner Headshot" style={styles.headshot} />
        <p style={styles.quote}>
        Hey! My name is Abhirup Chatterjee and I currently go to the University of Mississippi. I was a competitive high school debater for 4 years, but I’ve truly been a debater all my life. In fact, the reason why my parents signed me up in the first place was because they told me to stop arguing for them, and go get some trophies instead, so that’s exactly what I did. However, while competing in both state and national circuits, I noticed a huge equity problem. I saw many students just as talented as I was, often more talented, who simply did not have the resources they needed to fully reach their potential. I believe that if they had a platform like ours, they’d truly be able to do great. What I believe in most strongly is making debate open and available to everyone, because the communication skills and logical thinking that you gain from it are invaluable. We will not stop until we achieve our goals, and that goal is to have Speech and Debate across every single school across the country so students can really grow and learn how to reach their maximum capacity, and go even further beyond. 
        </p>
        <p style={styles.ownerName}>- Abhirup Chatterjee, CEO</p>
      </div>
    </div>
  );
}
  */
 export function HomePageTopText(){
  const navigate = useNavigate();
  return(
    <>
     <section className="showcase">
    <div className="overlay"></div>
    <div className="text">
      <h2>Best way for debate preparation. </h2> 
      <h5>The technology to give you an edge.</h5>
      <p>Use our site for all your academic debate needs.</p>
      <a onClick={() => navigate("/Chatbot")}>Get Started</a>
    </div>
  </section>
    </>
  )
 }