import PageNavbar from "../Components/PageNavbar";
import { useEffect,useState } from "react";
import axios from "axios";
import TournamentComponent from "../Components/TournamentComponents";
function Tournaments() {

  return (
    <>
    <PageNavbar/>
    <TournamentComponent/>
    </>
  );
}
  export default Tournaments;